import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/template/Layout'
import { Container } from '@material-ui/core'

export default function Article({ data }) {
  const post = data.markdownRemark
  return (
    <Layout title={post.frontmatter.title} description={post.frontmatter.description} type="Article" image="https://chateaubriant.jcef.asso.fr/static/b6563a053b3907c2c82b4c56278a3648/2a4de/logo.png">
      <Container>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`